import React, { Component } from 'react';
import {Route, Switch} from "react-router-dom";
import {withRouter} from 'react-router';
import ApplicationController from "./Controllers/ApplicationController";
import WelcomePage from "./Views/WelcomePage";
import 'semantic-ui-css/semantic.min.css';
import './App.css';
import "./KendoOvmEmploymentApp/KendoOvmEmploymentApp.css";


class App extends Component {

  render = () => {
    return(
      <div className='app'>
        <Switch>
          <Route
            exact
            path='/'
            render={(props) => {props.history.replace('/employment-application')}}
          />
          <Route
            path="/home"
            component={WelcomePage}
          />
          <Route
            path="/employment-application"
            component={ApplicationController}
          />
        </Switch>
      </div>
    )
  }

}

export default withRouter(App);
