import React, {Component} from 'react';
import APIService from '../API';

const states = [
  'Alaska',
  'Alabama',
  'Arizona',
  'Arkansas',
  'California',
  'Colorado',
  'Connecticut',
  'Delaware',
  'Florida',
  'Georgia',
  'Hawaii',
  'Idaho',
  'Illinois',
  'Indiana',
  'Iowa',
  'Kansas',
  'Kentucky',
  'Luisiana',
  'Maine',
  'Maryland',
  'Massachusetts',
  'Michigan',
  'Minnesota',
  'Mississippi',
  'Missouri',
  'Montana',
  'Nebraska',
  'Nevada',
  'New Hampshire',
  'New Jersey',
  'New Mexico',
  'New York',
  'North Carolina',
  'North Dakota',
  'Ohio',
  'Oklahoma',
  'Oregon',
  'Pennsylvania',
  'Rhode Island',
  'South Carolina',
  'South Dakota',
  'Tennessee',
  'Texas',
  'Utah',
  'Vermont',
  'Virginia',
  'Washington',
  'West Virginia',
  'Wisconsin',
  'Wyoming'
]
const eligibilityOptions = [
  'Yes',
  'No',
]
const criminalOptions = [
  'Yes',
  'No',
]
const education = [
  'High School',
  'College',
  'Trade/Business School',
  'Certifications/License(s)',
]
const referredBy = [
  'Walk-In',
  'Indeed',
  'Employee Referral',
  'Radio',
  'Sign',
  'Temp Agency',
  'Other',
]

let appVersion = process.env.REACT_APP_VERSION || "RUNTIME_REPLACE_REACT_APP_VERSION";

export const EmploymentApplicationContext = React.createContext();

export class EmploymentApplicationProvider extends Component {

  static defaultFormState = {
    // PERSONAL INFORMATION
    firstName: "",
    lastName: "",
    middleInitial: "",
    phone: "",
    address: "",
    city: "",
    state: "",
    zip: "",
    email: "",
    isEligibileUS: "Please Select...",
    beenConvicted: "Please Select...",
    convictedExplanation: "",

    // POSITION APPLIED FOR
    positionAppliedFor: "",
    availableSunday: false,
    availableMonday: false,
    availableTuesday: false,
    availableWednesday: false,
    availableThursday: false,
    availableFriday: false,
    availableSaturday: false,
    firstShiftAvailable: false,
    secondShiftAvailable: false,
    thirdShiftAvailable: false,
    referredBy: "Walk-In",
    referral: "",

    // PREVIOUS EMPLOYMENT
    employmentHistory: [],
    prevEmpStartDate1: new Date(),
    prevEmpEndDate1: new Date(),
    prevEmpName1: "",
    prevEmpPosition1: "",
    prevEmpSalary1: "",
    prevEmpReason1: "",
    prevEmpStartDate2: new Date(),
    prevEmpEndDate2: new Date(),
    prevEmpName2: "",
    prevEmpPosition2: "",
    prevEmpSalary2: "",
    prevEmpReason2: "",
    prevEmpStartDate3: new Date(),
    prevEmpEndDate3: new Date(),
    prevEmpName3: "",
    prevEmpPosition3: "",
    prevEmpSalary3: "",
    prevEmpReason3: "",
    empGapExplanation: "",

    // EDUCATIONAL HISTORY
    prevEducation: [],
    prevEduLevel1: "Please Select...",
    prevEduIsGraduated1: false,
    prevEduName1: "",
    prevEduLevel2: "Please Select...",
    prevEduIsGraduated2: false,
    prevEduName2: "",
    prevEduLevel3: "Please Select...",
    prevEduIsGraduated3: false,
    prevEduName3: "",

    // CONFIRMATION
    confirmDate: new Date(),
    eSignature: "",
  };

  static defaultState = {
    ...EmploymentApplicationProvider.defaultFormState,
    
    progressPercentage: 0,

    isPostingApplication: false,
    isSuccessfulDialogOpen: false,
    isFailedDialogOpen: false,

    errorCode: "",
  };

  state = EmploymentApplicationProvider.defaultState;

  postApplication = () => {
    console.log("Begin POST");
    this.setState({isPostingApplication: true})
    this.setEmploymentHistory();
    this.setPreviousEducation();
    let request = {
      firstName: this.state.firstName,
      lastName: this.state.lastName,
      middleInitial: this.state.middleInitial,
      phone: this.state.phone,
      address: this.state.address,
      city: this.state.city,
      state: this.state.state,
      zip: this.state.zip,
      email: this.state.email,
      isEligibileUS: this.state.isEligibileUS === "Yes" ? true : false,
      beenConvicted: this.state.beenConvicted === "Yes" ? true : false,
      convictedExplanation: this.state.convictedExplanation,
      positionAppliedFor: this.state.positionAppliedFor,
      preferredShift: this.state.preferredShift,
      availableSunday: this.state.availableSunday,
      availableMonday: this.state.availableMonday,
      availableTuesday: this.state.availableTuesday,
      availableWednesday: this.state.availableWednesday,
      availableThursday: this.state.availableThursday,
      availableFriday: this.state.availableFriday,
      availableSaturday: this.state.availableSaturday,
      startDate: this.state.startDate,
      firstShiftAvailable: this.state.firstShiftAvailable,
      secondShiftAvailable: this.state.secondShiftAvailable,
      thirdShiftAvailable: this.state.thirdShiftAvailable,
      referredBy: this.state.referredBy,
      referral: this.state.referral,
      employmentHistory: this.state.employmentHistory,
      empGapExplanation: this.state.empGapExplanation,
      prevEducation: this.state.prevEducation,
      confirmDate: this.state.confirmDate,
      eSignature: this.state.eSignature
    };
  
    return fetch(APIService.serverUrl + "/employment-application", {
      method: "POST",
      headers: {
        "content-type": "application/json",
        clientId: APIService.clientId,
        version: appVersion,
      },
      credentials: "include",
      body: JSON.stringify(request)
    })
      .then(response => {
        console.log("First then promise");
        if (response.ok) {
          console.log("Good post, ", response)
          return response;
        } else {
          console.log("Bad post");
          throw "Employment Application submission failed"
        }
      })
      .then(result => {
        console.log("Second then promise");
        this.setState({
          ...EmploymentApplicationProvider.defaultFormState,
          // employmentHistory: [],
          // prevEducation: [],
          // references: [],
          isSuccessfulDialogOpen: true
        })
      })
      .catch(err => {
        this.setState({
          errorCode: err,
          // isFailedDialogOpen: true
        })
        this.toggleFailedDialog();
        this.setState({
          employmentHistory: [],
          prevEducation: [],
          references: [],
        })
        console.log("Error: ", err)
      })
      .finally(() => {
        console.log("Final promise");
        this.setState({isPostingApplication: false})
      });
  }

  setEmploymentHistory = () => {
    let jobs = this.state.employmentHistory;
    let job1;
    let job2;
    let job3;
    if(this.state.prevEmpName1 !== "") {
      job1 = {
        startDate: this.state.prevEmpStartDate1,
        endDate: this.state.prevEmpEndDate1,
        name: this.state.prevEmpName1,
        position: this.state.prevEmpPosition1,
        salary: this.state.prevEmpSalary1,
        reason: this.state.prevEmpReason1
      }
      jobs.push(job1);
    }
    if(this.state.prevEmpName2 !== "") {
      job2 = {
        startDate: this.state.prevEmpStartDate2,
        endDate: this.state.prevEmpEndDate2,
        name: this.state.prevEmpName2,
        position: this.state.prevEmpPosition2,
        salary: this.state.prevEmpSalary2,
        reason: this.state.prevEmpReason2
      }
      jobs.push(job2);
    }
    if(this.state.prevEmpName3 !== "") {
      job3 = {
        startDate: this.state.prevEmpStartDate3,
        endDate: this.state.prevEmpEndDate3,
        name: this.state.prevEmpName3,
        position: this.state.prevEmpPosition3,
        salary: this.state.prevEmpSalary3,
        reason: this.state.prevEmpReason3
      }
      jobs.push(job3);
    }
    this.setState({employmentHistory: jobs});
  }

  setPreviousEducation = () => {
    let schools = this.state.prevEducation;
    let school1;
    let school2;
    let school3;
    if(this.state.prevEduName1 !== "") {
      school1 = {
        schoolLevel: this.state.prevEduLevel1,
        name: this.state.prevEduName1,
        isGraduated: this.state.prevEduIsGraduated1,
      }
      schools.push(school1);
    }
    if(this.state.prevEduName2 !== "") {
      school2 = {
        schoolLevel: this.state.prevEduLevel2,
        name: this.state.prevEduName2,
        isGraduated: this.state.prevEduIsGraduated2,
      }
      schools.push(school2);
    }
    if(this.state.prevEduName3 !== "") {
      school3 = {
        schoolLevel: this.state.prevEduLevel3,
        name: this.state.prevEduName3,
        isGraduated: this.state.prevEduIsGraduated3,
      }
      schools.push(school3);
    }
    this.setState({prevEducation: schools});
  }

  closeSuccessfulDialog = (history) => {
    this.setState({isSuccessfulDialogOpen: false});
    history.push("/");
  }

  toggleFailedDialog = () => {
    this.setState({isFailedDialogOpen: !this.state.isFailedDialogOpen});
  }

  updateContext = (object) => {
    this.setState(object)
  };

  serverUrl = process.env.REACT_APP_API_URL || "RUNTIME_REPLACE_REACT_APP_API_URL";


  render() {
    return (
      <EmploymentApplicationContext.Provider
        value={{
          states: states,
          eligibilityOptions: eligibilityOptions,
          criminalOptions: criminalOptions,
          education: education,
          referredBy: referredBy,
          state: this.state,
          postApplication: this.postApplication,
          closeSuccessfulDialog: this.closeSuccessfulDialog,
          toggleFailedDialog: this.toggleFailedDialog,
          updateContext: this.updateContext
        }}
      >
        {this.props.children}
      </EmploymentApplicationContext.Provider>
    )
  }
};
