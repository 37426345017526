import React, { Component } from "react";
import {withRouter} from "react-router";
import { Dialog, DialogActionsBar } from '@progress/kendo-react-dialogs';
import {EmploymentApplicationContext} from "../Contexts/EmploymentApplicationContext";
import {Form} from "@progress/kendo-react-form";
import WelcomePage from "../Views/WelcomePage";
import PersonalInformation from "./PersonalInformation";
import EmploymentDesired from "./EmploymentDesired";
import PreviousEmployment from "./PreviousEmployment";
import EducationalHistory from "./EducationalHistory";
import ESignature from "./ESignature";

let appVersion = process.env.REACT_APP_VERSION || "RUNTIME_REPLACE_REACT_APP_VERSION";

class EmploymentForm extends Component {
  
  componentDidMount() {
    this.scrollToTop();
  };
  
  scrollToTop = () => {
    window.scrollTo(0, 0)
  };
  
  
  render = () => {
    return(
      <EmploymentApplicationContext.Consumer>
        {(context) => (
          <div className="application">
            
            {/* VERSION */}
            <div className="version">
              <span>{`Version: ${appVersion}`}</span>
            </div>
            
            <Form
              render={(formRenderProps) => (
                <React.Fragment>
                  <WelcomePage/>
                  
                  <PersonalInformation/>
                  
                  <EmploymentDesired/>
                  
                  <PreviousEmployment/>
                  
                  <EducationalHistory/>
                  
                  <ESignature/>
                </React.Fragment>
              )}
            />
        
            {context.state.isSuccessfulDialogOpen &&
              <Dialog
                width={360}
                title={"Application Submission Successful"}
                onClose={() => context.closeSuccessfulDialog(this.props.history)}
              >
                <p style={{ margin: "25px", textAlign: "center" }}>Thank you for submitting. Give us time to accept and review your application.</p>
                <p style={{ margin: "25px", textAlign: "center" }}>Have a great day!</p>
                <DialogActionsBar>
                  <button className="k-button" onClick={() => context.closeSuccessfulDialog(this.props.history)}>Close Window</button>
                </DialogActionsBar>
              </Dialog>
            }
        
            {context.state.isFailedDialogOpen &&
              <Dialog
                width={350}
                title={"Application Submission Failed"}
                onClose={context.toggleFailedDialog}
              >
                <p style={{ margin: "8px 25px", textAlign: "center" }}>Seems something has gone wrong in submitting your application.</p>
                <p style={{ margin: "0px 25px", textAlign: "center" }}>Please contact us to report the issue at 419-522-5818</p>
                {/*<p style={{margin: "25px", textAlign: "center"}}>{this.state.errorCode}</p>*/}
                <DialogActionsBar>
                  <button className="k-button" onClick={context.toggleFailedDialog}>Close Window</button>
                </DialogActionsBar>
              </Dialog>
            }
          </div>
        )}
      </EmploymentApplicationContext.Consumer>
    )
  }
}

export default withRouter(EmploymentForm);
