import React, {Component} from "react";
import {withRouter} from "react-router";
import {EmploymentApplicationContext} from "../Contexts/EmploymentApplicationContext";

import {Segment} from "semantic-ui-react";
import { Button } from '@progress/kendo-react-buttons';
import { FormElement, FieldWrapper } from '@progress/kendo-react-form';
import { Label } from '@progress/kendo-react-labels';
import { Input, TextArea } from '@progress/kendo-react-inputs';
import { DropDownList } from '@progress/kendo-react-dropdowns';


class PersonalInformation extends Component {

  render = () => {
    return (
      <EmploymentApplicationContext.Consumer>
        {(context) => (
          <Segment className="applicationSegment">
            <FormElement>
              <legend className={'k-form-legend'} style={{marginBottom: "0px"}}>Personal Information</legend>
              <div className="groupingDiv">
                {/*LAST NAME*/}
                <FieldWrapper className="groupedField">
                  <Label>Last Name:</Label>
                  <Input
                    value={context.state.lastName}
                    type={'text'}
                    onChange={(e) => {context.updateContext({lastName: e.target.value})}}
                  />
                </FieldWrapper>
        
                {/*FIRST NAME*/}
                <FieldWrapper className="groupedField">
                  <Label>First Name:</Label>
                  <Input
                    value={context.state.firstName}
                    type={'text'}
                    onChange={(e) => {context.updateContext({firstName: e.target.value})}}
                  />
                </FieldWrapper>
              </div>
      
              <div className="groupingDiv">
                {/*MIDDLE INITIAL*/}
                <FieldWrapper className="groupedField">
                  <Label>
                    Middle Initial:
                  </Label>
                  <Input
                    value={context.state.middleInitial}
                    type={'text'}
                    onChange={(e) => {context.updateContext({middleInitial: e.target.value})}}
                  />
                </FieldWrapper>
        
                {/*PHONE NUMBER*/}
                <FieldWrapper className="groupedField">
                  <Label>Phone:</Label>
                  <Input
                    value={context.state.phone}
                    type={'text'}
                    onChange={(e) => {context.updateContext({phone: e.target.value})}}
                  />
                </FieldWrapper>
              </div>
      
              <div className="groupingDiv">
                {/*ADDRESS*/}
                <FieldWrapper className="groupedField">
                  <Label>Address:</Label>
                  <Input
                    value={context.state.address}
                    type={'text'}
                    onChange={(e) => {context.updateContext({address: e.target.value})}}
                  />
                </FieldWrapper>
        
                {/*CITY*/}
                <FieldWrapper className="groupedField">
                  <Label>City:</Label>
                  <Input
                    value={context.state.city}
                    type={'text'}
                    onChange={(e) => {context.updateContext({city: e.target.value})}}
                  />
                </FieldWrapper>
              </div>
      
              <div className="groupingDiv">
                {/*STATE*/}
                <FieldWrapper className="groupedField">
                  <Label>State:</Label>
                  <DropDownList
                    data={context.states}
                    value={context.state.state}
                    onChange={(event) => {
                      context.updateContext({state: event.target.value})
                    }}
                  />
                </FieldWrapper>
        
                {/*ZIP*/}
                <FieldWrapper className="groupedField">
                  <Label>Zip:</Label>
                  <Input
                    value={context.state.zip}
                    type={'text'}
                    onChange={(e) => {context.updateContext({zip: e.target.value})}}
                  />
                </FieldWrapper>
              </div>
  
              {/*EMAIL*/}
              <FieldWrapper className="groupedField">
                <Label>Email:</Label>
                <Input
                  value={context.state.email}
                  type={'text'}
                  onChange={(e) => {context.updateContext({email: e.target.value})}}
                />
              </FieldWrapper>
      
              {/*ELIGIBILITY*/}
              <FieldWrapper className="customfield600">
                <Label>Can you show proof of your eligibility to work in the U.S.?</Label>
                <DropDownList
                  style={{width: "200px"}}
                  data={context.eligibilityOptions}
                  value={context.state.isEligibileUS}
                  onChange={(event) => {
                    context.updateContext({isEligibileUS: event.target.value})
                  }}
                />
              </FieldWrapper>
      
              {/*CRIMINAL OFFENSE*/}
              <FieldWrapper className="customfield600">
                <Label>Have you ever been convicted of a criminal offense, other than minor traffic violations?</Label>
                <DropDownList
                  style={{width: "200px"}}
                  data={context.eligibilityOptions}
                  value={context.state.beenConvicted}
                  onChange={(event) => {
                    context.updateContext({beenConvicted: event.target.value})
                  }}
                />
              </FieldWrapper>
      
              {/*CRIMINAL OFFENSE CONT'D*/}
              {context.state.beenConvicted === "Yes" ?
                <FieldWrapper>
                  <Label>If yes; explain the type of offense, when you were convicted, and when you completed your sentence. (DO NOT use "will explain")</Label>
                  <TextArea
                    type={"text"}
                    rows={4}
                    value={context.state.convictedExplanation}
                    onChange={(e) => {context.updateContext({convictedExplanation: e.value})}}
                  />
                </FieldWrapper>
                :
                null
              }
            </FormElement>
          </Segment>
        )}
      </EmploymentApplicationContext.Consumer>

    )
  }
}

export default withRouter(PersonalInformation);