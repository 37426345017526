import React, {Component} from "react";
import {withRouter} from "react-router";
import {EmploymentApplicationContext} from "../Contexts/EmploymentApplicationContext";

import {Progress, Segment} from "semantic-ui-react";
import { Button } from '@progress/kendo-react-buttons';
import { Form, FormElement, FieldWrapper } from '@progress/kendo-react-form';
import { Label } from '@progress/kendo-react-labels';
import { Input, TextArea } from '@progress/kendo-react-inputs';
import { DatePicker } from '@progress/kendo-react-dateinputs';


class PreviousEmployment extends Component {
  
  render = () => {
    return (
      <EmploymentApplicationContext.Consumer>
        {(context) => (
          <Segment className="applicationSegment">
            <FormElement>
              <legend className={'k-form-legend'} style={{marginBottom: "0px"}}>Previous Employment</legend>
      
              <div className="subSegment">
                <div style={{display: 'flex'}}>
                  {/*START DATE*/}
                  <FieldWrapper className="customfield200" style={{marginRight: "10px"}}>
                    <Label>Start Date:</Label>
                    <DatePicker
                      format={"MMMM yyyy"}
                      value={context.state.prevEmpStartDate1}
                      onChange={event => context.updateContext({ prevEmpStartDate1: event.value })}
                    />
                  </FieldWrapper>
          
                  {/*END DATE*/}
                  <FieldWrapper className="customfield200">
                    <Label>End Date:</Label>
                    <DatePicker
                      format={"MMMM yyyy"}
                      value={context.state.prevEmpEndDate1}
                      onChange={event => context.updateContext({ prevEmpEndDate1: event.value })}
                    />
                  </FieldWrapper>
                </div>
  
                {/*NAME*/}
                <FieldWrapper className="customfield350">
                  <Label>Company Name:</Label>
                  <Input
                    value={context.state.prevEmpName1}
                    type={'text'}
                    onChange={(e) => {context.updateContext({prevEmpName1: e.target.value})}}
                  />
                </FieldWrapper>
        
                <div className="groupingDiv">
                  {/*POSITION*/}
                  <FieldWrapper className="groupedField">
                    <Label>Position:</Label>
                    <Input
                      value={context.state.prevEmpPosition1}
                      type={'text'}
                      onChange={(e) => {context.updateContext({prevEmpPosition1: e.target.value})}}
                    />
                  </FieldWrapper>
          
                  {/*SALARY*/}
                  <FieldWrapper className="groupedField">
                    <Label>Salary:</Label>
                    <Input
                      value={context.state.prevEmpSalary1}
                      type={'text'}
                      onChange={(e) => {context.updateContext({prevEmpSalary1: e.target.value})}}
                    />
                  </FieldWrapper>
                </div>
        
                {/*REASON FOR LEAVING*/}
                <FieldWrapper>
                  <Label>Reason for Leaving:</Label>
                  <Input
                    value={context.state.prevEmpReason1}
                    type={'text'}
                    onChange={(e) => {context.updateContext({prevEmpReason1: e.target.value})}}
                  />
                </FieldWrapper>
              </div>
      
              <div className="subSegment">
                <div style={{display: 'flex'}}>
                  {/*START DATE*/}
                  <FieldWrapper className="customfield200" style={{marginRight: "10px"}}>
                    <Label>Start Date:</Label>
                    <DatePicker
                      format={"MMMM yyyy"}
                      value={context.state.prevEmpStartDate2}
                      onChange={event => context.updateContext({ prevEmpStartDate2: event.value })}
                    />
                  </FieldWrapper>
          
                  {/*END DATE*/}
                  <FieldWrapper className="customfield200">
                    <Label>End Date:</Label>
                    <DatePicker
                      format={"MMMM yyyy"}
                      value={context.state.prevEmpEndDate2}
                      onChange={event => context.updateContext({ prevEmpEndDate2: event.value })}
                    />
                  </FieldWrapper>
                </div>
  
                {/*NAME*/}
                <FieldWrapper className="customfield350">
                  <Label>Company Name:</Label>
                  <Input
                    value={context.state.prevEmpName2}
                    type={'text'}
                    onChange={(e) => {context.updateContext({prevEmpName2: e.target.value})}}
                  />
                </FieldWrapper>
                
                <div className="groupingDiv">
                  {/*POSITION*/}
                  <FieldWrapper className="groupedField">
                    <Label>Position:</Label>
                    <Input
                      value={context.state.prevEmpPosition2}
                      type={'text'}
                      onChange={(e) => {context.updateContext({prevEmpPosition2: e.target.value})}}
                    />
                  </FieldWrapper>
          
                  {/*SALARY*/}
                  <FieldWrapper className="groupedField">
                    <Label>Salary:</Label>
                    <Input
                      value={context.state.prevEmpSalary2}
                      type={'text'}
                      onChange={(e) => {context.updateContext({prevEmpSalary2: e.target.value})}}
                    />
                  </FieldWrapper>
                </div>
        
                {/*REASON FOR LEAVING*/}
                <FieldWrapper>
                  <Label>Reason for Leaving:</Label>
                  <Input
                    value={context.state.prevEmpReason2}
                    type={'text'}
                    onChange={(e) => {context.updateContext({prevEmpReason2: e.target.value})}}
                  />
                </FieldWrapper>
              </div>
      
              <div className="subSegment lastSubSegment">
                <div style={{display: 'flex'}}>
                  {/*START DATE*/}
                  <FieldWrapper className="customfield200" style={{marginRight: "10px"}}>
                    <Label>Start Date:</Label>
                    <DatePicker
                      format={"MMMM yyyy"}
                      value={context.state.prevEmpStartDate3}
                      onChange={event => context.updateContext({ prevEmpStartDate3: event.value })}
                    />
                  </FieldWrapper>
          
                  {/*END DATE*/}
                  <FieldWrapper className="customfield200">
                    <Label>End Date:</Label>
                    <DatePicker
                      format={"MMMM yyyy"}
                      value={context.state.prevEmpEndDate3}
                      onChange={event => context.updateContext({ prevEmpEndDate3: event.value })}
                    />
                  </FieldWrapper>
                </div>
  
                {/*NAME*/}
                <FieldWrapper className="customfield350">
                  <Label>Company Name:</Label>
                  <Input
                    value={context.state.prevEmpName3}
                    type={'text'}
                    onChange={(e) => {context.updateContext({prevEmpName3: e.target.value})}}
                  />
                </FieldWrapper>
                
                <div className="groupingDiv">
                  {/*POSITION*/}
                  <FieldWrapper className="groupedField">
                    <Label>Position:</Label>
                    <Input
                      value={context.state.prevEmpPosition3}
                      type={'text'}
                      onChange={(e) => {context.updateContext({prevEmpPosition3: e.target.value})}}
                    />
                  </FieldWrapper>
          
                  {/*SALARY*/}
                  <FieldWrapper className="groupedField">
                    <Label>
                      Salary:
                    </Label>
                    <Input
                      value={context.state.prevEmpSalary3}
                      type={'text'}
                      onChange={(e) => {context.updateContext({prevEmpSalary3: e.target.value})}}
                    />
                  </FieldWrapper>
                </div>
        
                {/*REASON FOR LEAVING*/}
                <FieldWrapper>
                  <Label>Reason for Leaving:</Label>
                  <Input
                    value={context.state.prevEmpReason3}
                    type={'text'}
                    onChange={(e) => {context.updateContext({prevEmpReason3: e.target.value})}}
                  />
                </FieldWrapper>
              </div>
      
              {/*EMPLOYMENT GAPS*/}
              <FieldWrapper>
                <Label>Please explain any gaps in employment history. If you have no employment history, type “No history” here.</Label>
                <TextArea
                  type={"text"}
                  rows={4}
                  value={context.state.empGapExplanation}
                  onChange={(e) => {context.updateContext({empGapExplanation: e.value})}}
                />
              </FieldWrapper>
    
            </FormElement>
          </Segment>
        )}
      </EmploymentApplicationContext.Consumer>

    )
  }
}

export default withRouter(PreviousEmployment);