import React, {Component} from "react";
import {withRouter} from "react-router";
import {Route, Switch} from "react-router-dom";
import {EmploymentApplicationProvider} from "../Contexts/EmploymentApplicationContext";
import EmploymentForm from "../Views/EmploymentForm";


class ApplicationController extends Component {

  render = () => {
    let relativePath = this.props.match.path;
    return(
      <EmploymentApplicationProvider>
        <Switch>
          <Route
            path={relativePath}
            exact
            render={() =>
              <EmploymentForm/>
            }
          />
        </Switch>
      </EmploymentApplicationProvider>
    )
  }
}

export default withRouter(ApplicationController);
