import React, {Component} from "react";
import {withRouter} from "react-router";
import {EmploymentApplicationContext} from "../Contexts/EmploymentApplicationContext";

import {Segment} from "semantic-ui-react";
import { FormElement, FieldWrapper } from '@progress/kendo-react-form';
import { Label, FloatingLabel } from '@progress/kendo-react-labels';
import { Input, Checkbox, Switch, TextArea } from '@progress/kendo-react-inputs';
import { DatePicker } from '@progress/kendo-react-dateinputs';
import { DropDownList } from '@progress/kendo-react-dropdowns';


class EmploymentDesired extends Component {

  render = () => {
    return (
      <EmploymentApplicationContext.Consumer>
        {(context) => (
          <Segment className="applicationSegment">
            <FormElement>
              <legend className={'k-form-legend'} style={{marginBottom: "0px"}}>Employment Desired</legend>
      
              {/*POSITION APPLIED FOR*/}
              <FieldWrapper className="customfield600">
                <Label>Position(s) Applied For:</Label>
                <Input
                  value={context.state.positionAppliedFor}
                  type={'text'}
                  onChange={(e) => {context.updateContext({positionAppliedFor: e.target.value})}}
                />
              </FieldWrapper>
      
              {/*DAY AVAILABILITY*/}
              <FieldWrapper>
                <Label>Days Available to Work:</Label>
                <div>
                  <Checkbox
                    className="checkboxMargin"
                    label={'Sunday'}
                    value={context.state.availableSunday}
                    onChange={() => context.updateContext({availableSunday: !context.state.availableSunday})}
                  />
                  <Checkbox
                    className="checkboxMargin"
                    label={'Monday'}
                    value={context.state.availableMonday}
                    onChange={() => context.updateContext({availableMonday: !context.state.availableMonday})}
                  />
                  <Checkbox
                    className="checkboxMargin"
                    label={'Tuesday'}
                    value={context.state.availableTuesday}
                    onChange={() => context.updateContext({availableTuesday: !context.state.availableTuesday})}
                  />
                  <Checkbox
                    className="checkboxMargin"
                    label={'Wednesday'}
                    value={context.state.availableWednesday}
                    onChange={() => context.updateContext({availableWednesday: !context.state.availableWednesday})}
                  />
                  <Checkbox
                    className="checkboxMargin"
                    label={'Thursday'}
                    value={context.state.availableThursday}
                    onChange={() => context.updateContext({availableThursday: !context.state.availableThursday})}
                  />
                  <Checkbox
                    className="checkboxMargin"
                    label={'Friday'}
                    value={context.state.availableFriday}
                    onChange={() => context.updateContext({availableFriday: !context.state.availableFriday})}
                  />
                  <Checkbox
                    className="checkboxMargin"
                    label={'Saturday'}
                    value={context.state.availableSaturday}
                    onChange={() => context.updateContext({availableSaturday: !context.state.availableSaturday})}
                  />
                </div>
              </FieldWrapper>
      
              {/*SHIFT AVAILABILITY*/}
              <FieldWrapper>
                <Label>Shifts Available to Work:</Label>
                <div>
                  <Checkbox
                    className="checkboxMargin"
                    label={'1st'}
                    value={context.state.firstShiftAvailable}
                    onChange={() => context.updateContext({firstShiftAvailable: !context.state.firstShiftAvailable})}
                  />
                  <Checkbox
                    className="checkboxMargin"
                    label={'2nd'}
                    value={context.state.secondShiftAvailable}
                    onChange={() => context.updateContext({secondShiftAvailable: !context.state.secondShiftAvailable})}
                  />
                  <Checkbox
                    className="checkboxMargin"
                    label={'3rd'}
                    value={context.state.thirdShiftAvailable}
                    onChange={() => context.updateContext({thirdShiftAvailable: !context.state.thirdShiftAvailable})}
                  />
                </div>
              </FieldWrapper>
      
              {/*HOW DID YOU HEAR ABOUT JOB OPENINGS*/}
              <FieldWrapper className="">
                <Label>How did you hear about Ohio Valley Mfg job openings?</Label>
                <DropDownList
                  className="customfield350"
                  data={context.referredBy}
                  value={context.state.referredBy}
                  onChange={(event) => {
                    context.updateContext({referredBy: event.target.value})
                  }}
                />
              </FieldWrapper>
      
              {/*REFERRED BY*/}
              {context.state.referredBy === "Employee Referral" &&
                <FieldWrapper className="customfield350">
                <Label>Referred By:</Label>
                <Input
                  value={context.state.referral}
                  type={'text'}
                  onChange={(e) => {context.updateContext({referral: e.target.value})}}
                />
              </FieldWrapper>
              }
            </FormElement>
          </Segment>
        )}
      </EmploymentApplicationContext.Consumer>

    )
  }
}

export default withRouter(EmploymentDesired);