import React, {Component} from "react";
import {withRouter} from "react-router";
import {EmploymentApplicationContext} from "../Contexts/EmploymentApplicationContext";

import {Progress, Segment} from "semantic-ui-react";
import { Button } from '@progress/kendo-react-buttons';
import { Form, FormElement, FieldWrapper } from '@progress/kendo-react-form';
import { Label } from '@progress/kendo-react-labels';
import { Input } from '@progress/kendo-react-inputs';
import { DatePicker } from '@progress/kendo-react-dateinputs';
import { Dialog, DialogActionsBar } from '@progress/kendo-react-dialogs';


class ESignature extends Component {

  render = () => {
    return (
      <EmploymentApplicationContext.Consumer>
        {(context) => (
          <Segment className="applicationSegment" style={{display: "flex", flexDirection: "column"}}>
            <FormElement>
              <legend className={'k-form-legend'} style={{marginBottom: "0px"}}>E-Signature</legend>
  
              <h5 style={{marginTop: "15px"}}>
                I authorize investigation of all statements contained in this application. I understand that any misrepresentation or omission of facts is cause for dismissal should I be hired. I hereby waive and release Ohio Valley Mfg from any and all claims resulting from any and all injury or illness incurred or sustained from or during my pre-employment tour of or testing at the Ohio Valley Mfg facilities or premises due to my own acts or omissions. Further, I understand and agree that, if I am hired by Ohio Valley Mfg, my employment is for no definite period of time and may, regardless of the date of payment of my wages and salary, be terminated at any time without cause and/or without notice. Also, I understand that before I can be hired by Ohio Vally Manufacturing, I must take and pass a pre-employment drug screen and sign a consent form relating to the administration of such drug screen. I further understand that my refusal to either take the drug screen or sign the consent form will result in my being ineligible for employment with the company both now and in the future.
              </h5>
  
              {/*CONFIRM DATE*/}
              <FieldWrapper className="customfield200">
                <Label>Date:</Label>
                <DatePicker
                  value={context.state.confirmDate}
                  onChange={event => context.updateContext({ confirmDate: event.value })}
                />
              </FieldWrapper>
  
              {/*SIGNATURE*/}
              <FieldWrapper className="customfield350">
                <Label>E-Signature:</Label>
                <Input
                  value={context.state.eSignature}
                  type={'text'}
                  onChange={(e) => {context.updateContext({eSignature: e.target.value})}}
                />
              </FieldWrapper>
            </FormElement>

            {/*SUBMIT BUTTON*/}
            <div className="buttonSection">
              <Button
                className="nextButton"
                primary
                onClick={() => context.postApplication()}
              >
                SUBMIT
              </Button>
            </div>
          </Segment>
        )}
      </EmploymentApplicationContext.Consumer>

    )
  }
}

export default withRouter(ESignature);