import React, {Component} from "react";
import {withRouter} from "react-router";
import {EmploymentApplicationContext} from "../Contexts/EmploymentApplicationContext";

import {Progress, Segment} from "semantic-ui-react";
import { Button } from '@progress/kendo-react-buttons';
import { Form, FormElement, FieldWrapper } from '@progress/kendo-react-form';
import { Label } from '@progress/kendo-react-labels';
import { Input, Switch, } from '@progress/kendo-react-inputs';
import { DropDownList } from '@progress/kendo-react-dropdowns';


class EducationalHistory extends Component {

  render = () => {
    return (
      <EmploymentApplicationContext.Consumer>
        {(context) => (
          <Segment className="applicationSegment">
            <FormElement>
              <legend className={'k-form-legend'} style={{marginBottom: "0px"}}>Educational History</legend>
      
              <div className="subSegment">
                <div className="groupingDiv">
                  {/*SCHOOL LEVEL*/}
                  <FieldWrapper className="groupedField">
                    <Label>School Level:</Label>
                    <DropDownList
                      data={context.education}
                      value={context.state.prevEduLevel1}
                      onChange={(event) => {
                        context.updateContext({prevEduLevel1: event.target.value})
                      }}
                    />
                  </FieldWrapper>
          
                  {/*GRADUATE CHECK*/}
                  <FieldWrapper className="groupedField">
                    <Label>Graduated?</Label>
                    <Switch
                      onLabel={"Yes"}
                      offLabel={"No"}
                      checked={context.state.prevEduIsGraduated1}
                      onChange={(event) => { context.updateContext({prevEduIsGraduated1: event.target.value}) }}
                    />
                  </FieldWrapper>
                </div>
        
                {/*NAME AND LOCATION*/}
                <FieldWrapper className="customfield600">
                  <Label>Name:</Label>
                  <Input
                    value={context.state.prevEduName1}
                    type={'text'}
                    onChange={(e) => {context.updateContext({prevEduName1: e.target.value})}}
                  />
                </FieldWrapper>
              </div>
      
              <div className="subSegment">
                <div className="groupingDiv">
                  {/*SCHOOL LEVEL*/}
                  <FieldWrapper className="groupedField">
                    <Label>School Level:</Label>
                    <DropDownList
                      data={context.education}
                      value={context.state.prevEduLevel2}
                      onChange={(event) => {
                        context.updateContext({prevEduLevel2: event.target.value})
                      }}
                    />
                  </FieldWrapper>
          
                  {/*GRADUATE CHECK*/}
                  <FieldWrapper className="groupedField">
                    <Label>Graduated?</Label>
                    <Switch
                      onLabel={"Yes"}
                      offLabel={"No"}
                      checked={context.state.prevEduIsGraduated2}
                      onChange={(event) => { context.updateContext({prevEduIsGraduated2: event.target.value}) }}
                    />
                  </FieldWrapper>
                </div>
        
                {/*NAME AND LOCATION*/}
                <FieldWrapper className="customfield600">
                  <Label>Name:</Label>
                  <Input
                    value={context.state.prevEduName2}
                    type={'text'}
                    onChange={(e) => {context.updateContext({prevEduName2: e.target.value})}}
                  />
                </FieldWrapper>
              </div>
      
              <div className="subSegment lastSubSegment">
                <div className="groupingDiv">
                  {/*SCHOOL LEVEL*/}
                  <FieldWrapper className="groupedField">
                    <Label>School Level:</Label>
                    <DropDownList
                      data={context.education}
                      value={context.state.prevEduLevel3}
                      onChange={(event) => {
                        context.updateContext({prevEduLevel3: event.target.value})
                      }}
                    />
                  </FieldWrapper>
          
                  {/*GRADUATE CHECK*/}
                  <FieldWrapper className="groupedField">
                    <Label>Graduated?</Label>
                    <Switch
                      onLabel={"Yes"}
                      offLabel={"No"}
                      checked={context.state.prevEduIsGraduated3}
                      onChange={(event) => { context.updateContext({prevEduIsGraduated3: event.target.value}) }}
                    />
                  </FieldWrapper>
                </div>
        
                {/*NAME AND LOCATION*/}
                <FieldWrapper className="customfield600">
                  <Label>Name:</Label>
                  <Input
                    value={context.state.prevEduName3}
                    type={'text'}
                    onChange={(e) => {context.updateContext({prevEduName3: e.target.value})}}
                  />
                </FieldWrapper>
              </div>
            </FormElement>
          </Segment>
        )}
      </EmploymentApplicationContext.Consumer>

    )
  }
}

export default withRouter(EducationalHistory);