import React, { Component } from "react";
import {withRouter} from "react-router";
import {Segment} from "semantic-ui-react";


class WelcomePage extends Component {

  render = () => {
    return (
      <Segment className="applicationSegment">
        <h2
          className="blueFont"
          style={{alignSelf: "center", textAlign: "center", margin: "5px 0px 20px"}}
        >
          Welcome to Ohio Valley Mfg Employment Application!
        </h2>
    
        <div className="applicationHeaderSegment" style={{alignSelf: "center"}}>
          <img id="ovmLogo" src="images/ovm-logo.jpg" alt="OVM Logo"/>
          <div className="applicationHeaderDetails">
            <h4 className="blueFont address">Ohio Valley Manufacturing</h4>
            <h4 className="blueFont address">1501 Harrington Memorial Road</h4>
            <h4 className="blueFont address">Mansfield, Ohio 44903</h4>
            <h4 className="blueFont address">Telephone: 419-522-5818</h4>
          </div>
    
        </div>
        <h5
          className="blueFont"
          style={{textAlign: "center", alignSelf: "center",fontWeight: "400"}}
        >
          Qualified applicants are considered for all positions without regard to race, color, religion, sex, national origin, age, marital or veteran status, or the presence of a non-job related medical condition or handicap.
        </h5>
      </Segment>
    )
  }
}

export default withRouter(WelcomePage);